<template>
	<div>
		<H5header :type="2"></H5header>
		<p class="title">请选择注册账号类型</p>
		
		<div class="typesof" @click="getregister('/h5/rersonalRegistration')">
			<div class="name">
				个人账户主要应用于裁判员注册、信息浏览等个人业务。
			</div>
		</div>
		<div class="typesof2" @click="getregister('/h5/groupRegistration')">
			<div class="name">
				团体账户主要应用于赛事信息登记、裁判员管理等团体业务。
			</div>
		</div>
	</div>
</template>

<script>
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {}
		},
		created() {
			this.$getwxsdk('注册账号类型 - 北京市体育竞赛管理和国际交流中心')
		},
		methods: {
			getregister(path){
				this.$router.push({path: path})
			}
		}
	}
</script>

<style scoped lang="scss">
	.title{
		font-size: 15PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		margin-top: 36PX;
		text-align: center;
	}
	
	.typesof{
		margin: 32PX 22PX 0;
		height: 25vh;
		background: url('image/gr.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.name{
			width: 35%;
			font-size: 10PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #383838;
			padding-top: 25%;
			margin-left: 54%;
		}
	}
	.typesof2{
		margin: 32PX 22PX 0;
		height: 25vh;
		background: url('image/tt.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.name{
			width: 35%;
			font-size: 10PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #383838;
			padding-top: 25%;
			margin-left: 54%;
		}
	}
</style>